<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Consultas</h4>
            <div class="small text-muted">Vista de las consulta recibidas filtradas por formularios</div>
          </b-col>

          <b-col sm="5">            
            <div>
              <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
                <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                  <i class="fa fa-filter"></i> Mostrar Filtros
                </b-button>
              </b-col>
              <b-col sm="12" class="d-none d-md-block" v-else>
                <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                  <i class="fa fa-window-close"></i>
                </b-button>
              </b-col>
            </div>
          </b-col>  
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <div>
          <b-row v-if="table.mostrarFiltros" class="mb-3">            
            <b-col sm="2">
              <v-select :options="arr.select.forms" v-model="filters.forms" placeholder="Formularios" :multiple="true" :select-on-tab="true"></v-select>
            </b-col>
           
            <b-col sm="2">
              <b-button variant="outline-dark" @click="filterQueries()">
                <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                Filtrar
              </b-button>
            </b-col>  

            <b-col sm="8" class="p-1">
              <b-link>
                <export-excel
                  class = 'pull-right mr-2 mt-2'
                  :data = "arr.export"
                  worksheet = "Consultas formularios"
                  name = "list-forms.xls">
                  Exportar <b-icon icon="download"></b-icon>
                </export-excel>            
              </b-link>
            </b-col>                        
          </b-row>
        </div>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"                    
                    :current-page="table.currentPage"
                    :per-page="table.perPage" 
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"                                         
                    v-if="table.items.length">
         
                <template v-slot:row-details="row">
                  <b-card> 
                    <b>Fecha:</b> {{moment(row.item.created_at).format('DD MMMM YYYY')}}<br>
                    
                    <div v-if="row.item.name">
                      <b>Nombre:</b> {{row.item.name}}<br>
                    </div>

                    <div v-if="row.item.email">
                      <b>Email:</b> <a :href="'mailto:'+row.item.email">{{row.item.email}}</a><br>
                    </div>

                    <div v-if="row.item.phone">
                      <b>Teléfono:</b> <a :href="'tel:'+row.item.phone">{{row.item.phone}}</a>
                    </div>

                    <div v-for="(element, key) in JSON.parse(row.item.form.fields)" :key="key">
                      <div v-if="element.name!='name' && 
                                 element.name!='email' &&
                                 element.name!='phone' &&
                                 element.name!='message'">
                        
                        <div v-if="row.item.content">
                          <div v-if="element.type == 'date'">
                            <div v-if="JSON.parse(row.item.content)[element.name]">
                              <b class="form-detail-content-field">{{element.title}}:</b> {{moment(JSON.parse(row.item.content)[element.name]).format('DD MMMM YYYY')}} 
                            </div>
                            <div v-else>
                              <b class="form-detail-content-field">{{element.title}}:</b> 
                            </div>
                          </div>
                          
                          <div v-if="element.type == 'checkbox'">
                            <div v-if="JSON.parse(row.item.content)[element.name]==true">
                              <b class="form-detail-content-field">{{element.title}}:</b> SI
                            </div>
                            <div v-else>
                              <b class="form-detail-content-field">{{element.title}}:</b> NO
                            </div> 
                          </div>
                          
                          <div v-if="element.type == 'email'">
                            <div v-if="JSON.parse(row.item.content)[element.name]">
                              <b class="form-detail-content-field">{{element.title}}:</b> <a :href="'mailto:'+JSON.parse(row.item.content)[element.name]">{{JSON.parse(row.item.content)[element.name]}}</a> 
                            </div>
                            <div v-else>
                              <b class="form-detail-content-field">{{element.title}}:</b>
                            </div>
                          </div>

                          <div v-if="element.type == 'file'">
                            <div v-if="JSON.parse(row.item.content)[element.name]">
                              <b class="form-detail-content-field">{{element.title}}:</b> <a target="_blank" :href="publicPath + '/' + JSON.parse(row.item.content)[element.name]">{{publicPath + '/' + JSON.parse(row.item.content)[element.name]}}</a> 
                            </div>
                            <div v-else>
                              <b class="form-detail-content-field">{{element.title}}:</b>
                            </div>                            
                          </div>

                          <div v-if="element.type != 'date' &&
                                     element.type != 'checkbox' &&
                                     element.type != 'date' &&
                                     element.type != 'file'">
                            <b class="form-detail-content-field">{{element.title}}:</b> {{JSON.parse(row.item.content)[element.name]}} 
                          </div>


                        </div>

                      </div>
                    </div>

                    <br>
                    <div v-if="row.item.message">
                      <b>Mensaje:</b> <div v-html="row.item.message"></div>                                      
                    </div>
                  </b-card>
                </template>

                <template v-slot:cell(id)="data">
                  <b>{{ data.item.id }}</b>
                </template>

                <template v-slot:cell(forms_id)="data">                  
                  <b>{{ data.item.form.name }}</b>
                </template>         

                <template v-slot:cell(created_at)="data">
                  {{moment(data.item.created_at).format('DD MMMM YYYY')}}
                </template>

                <template v-slot:cell(name)="data">
                  <div v-if="data.item.name">
                    <b>{{ data.item.name }}</b>
                  </div>
                  <div v-else>
                    -
                  </div>                  
                </template>       

                <template v-slot:cell(email)="data">
                  <div v-if="data.item.email">
                    <b><a :href="'mailto:'+data.item.email">{{data.item.email}}</a></b>
                  </div>
                  <div v-else>
                    -
                  </div>
                </template> 

                <template v-slot:cell(phone)="data">
                  <div v-if="data.item.phone">
                    <b>{{ data.item.phone }}</b>
                  </div>
                  <div v-else>
                    -
                  </div>                  
                </template>

                <template v-slot:cell(message)="data">
                  <div v-if="data.item.message">
                    <div v-if="data.item.message.length>40">
                      {{data.item.message.substr(0, 40)}} ...
                    </div>
                    <div v-else>                    
                      {{data.item.message}}
                    </div>
                  </div>
                  <div v-else>
                    -
                  </div>                                      
                </template>      

                <template v-slot:cell(observations)="data">
                  <span v-if="data.item.sales_flow_leads_id">
                      <b-icon icon="info-circle-fill" v-b-tooltip.hover title="Enviado al CRM"></b-icon>
                  </span>                    
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                    
                    <b-dropdown-item @click="convert(data.item)" v-if="!data.item.sales_flow_leads_id && parameters.haveConvertContacts">
                      <b-icon icon="shuffle"></b-icon> Enviar a CRM
                    </b-dropdown-item>

                    <b-dropdown-item @click="openDetail(data.index)">
                      <b-icon icon="chevron-compact-down"></b-icon> Resumen
                    </b-dropdown-item>                   
                                                                        
                    <b-dropdown-header>Acciones</b-dropdown-header>                    
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>            
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'  
  import Funciones from '@/handler/funciones'
  import Param from '@/config/parameters'
  import moment from 'moment' 

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.FORMULARIOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'detailForms',
          elements: {}
        },
        parameters: {
          haveConvertContacts: Helper.hasParametersAccess(Param.P24),
        },        
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle"},            
            {key: 'forms_id', label: 'Formulario', sortable: true, class:"align-middle"},
            {key: 'created_at', label: 'Fecha', class:"align-middle"},
            {key: 'name', label: 'Nombre', class:"align-middle"},
            {key: 'phone', label: 'Teléfono', class:"align-middle"},
            {key: 'email', label: 'Email', class:"align-middle"},            
            {key: 'message', label: 'Mensaje', class:"align-middle"},
            {key: 'observations', label: '', class:"align-middle"},
            {key: 'f_action', label:'', class:"align-middle"},
          ],          
          mostrarFiltros: true,          
          currentPage: 1,
          perPage: 50,
        },
        crud: {
          form: {
            id: 0,
            name: '',
            forms_id: 0,
            forms: null,            
            email: '',
            phone: '',
            message: '',
          },          
        },
        arr: {
          export: [],
          formDetails: [],
          select: {
            forms: []
          }
        },
        filters: {
          forms: [],
        },
        publicPath: '',
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted() {                            
      this.loadQueries()
      this.filterLoad()
    },   
    methods: {
      getRowCount (items) {
        return items.length
      },  
      onRowSelected(item) {      
        this.arr.formDetails.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.filterQueries()
      },  
      loadQueries() {        
        var result = serviceAPI.obtenerFormsDetail()

        result.then((response) => {                    
          var data = response.data          
          this.table.items = data.data
          this.arr.formDetails = data.data
          this.publicPath = data.public_path
          this.prepareExport(data.data)

          this.table.items.forEach(element => {
            if(element.sales_flow_leads_id) {
              element._rowVariant = 'success'                          
            }
          })

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true                
          }                                    
        }) 
        .catch(error => {          
          this.$awn.alert(ErrorToken.valid(error));
        })       
      },        
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Consulta',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarFormsDetail(this.crud.form.id);

            result.then((response) => {                           
              loader.hide()
              this.filterQueries()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },  
      filterLoad() {
        this.filterLoadForms()
      },
      filterLoadForms() {
        var result = serviceAPI.obtenerForms()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.forms = []
          data.forEach(element => {               
            this.arr.select.forms.push({ code: element.id, label: element.name })            
          });          
        })  
      },  
      filterQueries() {
        var result = serviceAPI.filterFormsDetail(this.filters)        
        result.then((response) => {
          var data = response.data
          this.table.items = data.data
          this.arr.formDetails = data.data
          this.publicPath = data.public_path
          this.prepareExport(data.data)

          this.table.items.forEach(element => {
            if(element.sales_flow_leads_id) {
              element._rowVariant = 'success'                          
            }
          })

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true                
          }                                    
        })           
        .catch(error => {          
          this.$awn.alert(ErrorToken.valid(error));
        })         
      }, 

      prepareExport(data) {      
        this.arr.export = []        
        data.forEach(element => {
          var formID = 0
          var formName = ""
          
          if(element.form) {
            formID = element.form.id
            formName = element.form.name            
          }                          

          this.arr.export.push({
            id: element.id,            
            date: element.created_at,            
            formID: formID,
            formName: formName,
            name: element.name,
            email: element.email,
            phone: element.phone,
            message: element.message,            
          })          
        });
      },

      convert(item) {
        this.$bvModal.msgBoxConfirm('¿Desea convertir esta consulta en un Leads del CRM?', {
          title: 'Convertir consulta',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'CONVERTIR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {               
            let loader = this.$loading.show();
            var result = serviceAPI.convertFormsDetail(item)        
            result.then((response) => {
              loader.hide()
              this.filterQueries()       
              this.$awn.success("Consulta convertida");
            })           
            .catch(error => {          
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })    
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })                
      }
    }      
  }
</script>
<style>
  .form-detail-content-field {
    text-transform: capitalize;
  }
</style>